import * as React from 'react';

function EditIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.506 1.799a1.02 1.02 0 00-1.443 0L9.307 3.555l3.138 3.199 1.756-1.756a1.02 1.02 0 000-1.443l-1.695-1.756zm-.76 5.653L8.61 4.253l-5.755 5.754a1.02 1.02 0 00-.279.522l-1.07 3.666a.255.255 0 00.3.3l3.666-1.01c.198-.039.38-.136.522-.278l5.754-5.755zM14.5 13.5H7l-1 1h8.5a.5.5 0 000-1z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EditIcon;
