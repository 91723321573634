import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'reactstrap';

const IconButton = (props) => {
  const { iconAlign, buttonIcon, labelText, className, ...rest } = props;
  const isLeftAlign = iconAlign === 'left';
  const buttonClasses = classnames('button-icon-wrapper', className, {
    'icon-left': isLeftAlign,
  });

  return (
    <Button {...rest} className={buttonClasses}>
      <div className="button-icon-label">
        {!isLeftAlign && buttonIcon}
        {!!labelText && <span className="label">{labelText}</span>}
        {isLeftAlign && buttonIcon}
      </div>
    </Button>
  );
};

IconButton.defaultProps = {
  className: '',
  color: 'falcon-default',
  iconAlign: 'right',
  labelText: '',
  buttonIcon: null,
};

IconButton.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  labelText: PropTypes.string,
  buttonIcon: PropTypes.node,
  iconAlign: PropTypes.oneOf(['left', 'right']),
};

export default IconButton;
